import React from 'react';

const Footer = () => {
  return (
    <>
      {/* line */}
      <div className="w-full h-px bg-[#ABB2BF] mt-20 mb-8"></div>
      {/* footer */}
      <div data-aos="fade-up" className="px-5 max-w-[1560px] mx-auto">
        {/* top */}
        <div className="flex justify-between gap-y-10 flex-wrap">
          {/* left */}
          <div className="mx-auto md:mx-0">
            <div className="flex items-center gap-8 mb-3 ">
              {/* logo */}
              <div className="flex gap-2 items-center text-2xl text-white font-bold">
                <img src={require("./imgs/Logo.png")} alt="" />
                <span>Hashini</span>
              </div>
              {/* email */}
              {/* Wrap email in anchor tag with mailto link */}
              <a href="mailto:hashini.sugandika@gmail.com" className="text-[#ABB2BF] hover:underline">
                sugandika.hashini@gmail.com
              </a>
            </div>
            {/* disc */}
            <div className="text-[#ffffff]">
              <p>Web designer and front-end developer based in Sri Lanka</p>
            </div>
          </div>
          {/* right */}
          <div className="mx-auto md:mx-0">
            {/* title */}
            <h2 className="text-white text-2xl font-medium mb-3">Media</h2>
            {/* media */}
            <div className="flex items-center gap-2">
              <a href="https://www.linkedin.com/in/hashini-sugandika-7b6b732a3/">
                {/* Add custom class to control LinkedIn icon size */}
                <img src={require("./imgs/linkedin.png")} alt="" target="_blank" rel="noopener noreferrer" className="w-6 h-6" />
              </a>
              {/* Add Gmail icon with mailto link */}
              <a href="mailto:sugandika.hashini@gmail.com">
                <img src={require("./imgs/gmail.png")} alt="" target="_blank" rel="noopener noreferrer" className="w-6 h-6" />
              </a>
              {/* <a href="https://github.com/hashini-sugandika" target="_blank" rel="noopener noreferrer" className="w-6 h-6">
                <img src={require("./imgs/github.png")} alt="" />
              </a> */}
            </div>
          </div>
        </div>
        {/* bottom */}
        <div className="text-center text-[#ABB2BF] pb-8 mt-12">
          © Copyright 2024. Made by Hashini
        </div>
      </div>
    </>
  );
};

export default Footer;
