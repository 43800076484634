// App.js
import './App.css';
import {Routes, Route} from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Footer from './components/footer/Footer';
import Home from './components/Home.jsx';
import Projects from './components/projects_section/Projects.jsx';
import JapuraEdge from "../src/components/projects_section/imgs/JapuraEdge.png";
import third from "../src/components/projects_section/imgs/travel app.png";
import slider from "../src/components/projects_section/imgs/slider.png";
import sushiman from "../src/components/projects_section/imgs/sushi website.png";
import hilink from "../src/components/projects_section/imgs/travel app.png";
import resturant from "../src/components/projects_section/imgs/resturant website.png";
import nike from "../src/components/projects_section/imgs/nike website.png";
import Self from "./components/about_section/About.jsx";
import Contact from './components/contact_section/Contact.jsx';
import Education from './components/education_page/Education.jsx';


function App() {
  const projects= [
   
      {
        img: JapuraEdge,
        langs: ["ReactJS", "Type Script", "NodeJs", "ExspressJs", "MongoDB", "Azure"],
        title: "Japura Edge",
        disc: "Connecting university students with everything they need, all in one place",
        githubLink: ""
      },
      {
        img: slider,
        langs: ["html", "css", "javascript", "Node.js"],
        title: "Slider+",
        disc: "Your gateway to smart, secure living ",
        githubLink:""
      },
      {
        img: third,
        langs: ["html", "css", "javascript", "Node.js", "python"],
        title: "Campus Buddy",
        disc: "Get answers to your kahoot quiz ",
        githubLink:""
      },
      {
        img: third,
        langs: ["html", "css", "javascript", "Node.js", "python"],
        title: "KASH",
        disc: "Launch Young Innovators: Empowering Kids to Build Their Dreams!",
        githubLink:""
      },
      {
        img: sushiman,
        langs: ["html", 
        "css"],
        title: "SUSHIMAN",
        disc: "Taste the Tradition: Your Sushi Journey Begins Here ",
        githubLink:""
      },
      {
        img: hilink,
        langs: ["ReactJS", "NextJS", "Tailwind CSS"],
        title: "Hilink",
        disc: "Explore the wonders of Sri Lanka like never before ",
        githubLink:""
      },
      {
        img: resturant,
        langs: ["ReactJS", "Tailwind CSS"],
        title: "Grilled Heaven",
        disc: "Your premier destination for culinary excellence ",
        githubLink:""
      },
      {
        img: nike,
        langs: ["ReactJS", "Tailwind CSS"],
        title: "Nike Website",
        disc: " revolutionary online platform for sports enthusiasts ",
        githubLink:""
      },
      {
        img: third,
        langs: ["ReactJS", "RapidAPI"],
        title: "Youtube Clone",
        disc: "Explore, Craft, Share: Your ultimate video platform ",
        githubLink:""
      }
  ];
  return (
    
      <div className="bg-[#282C33] page-full">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home projectsList={projects.slice(0, 3)}/>}/>
          <Route path="/education" element={<Education/>}/>
          <Route path="/projects" element={<Projects projects={projects} />} />
          <Route path="/Self" element={<Self/>}/>
          <Route path="/Contact" element={<Contact/>}/>
        </Routes>
        <Footer/>
      </div>
    
  );
}

export default App;
