import "./style.css";

const Contact = () => {
  return (
    <div className="contact-full">
      <div  data-aos = "fade-up" data-aos-delay="1000" className="px-5 max-w-[1560px] mx-auto  py-10">
        {/* top */}
        <div className="">
          {/* header */}
          <div className=" mb-10">
            <div className=" text-white w-2/3 font-medium text-[32px] flex items-center gap-2">
              <div className="">
                <span className="text-[#40908D]">#</span>contacts
              </div>
              <div className="line w-1/3 h-px bg-[#40908D]"></div>
            </div>
          </div>
        </div>
        {/* bottom */}
        <div className=" flex items-start flex-wrap gap-10 justify-between">
          {/* left */}
          <div className="left w-full md:w-1/2">
            <p className=" text-[#ABB2BF]">
            I'm currently seeking the right opportunity in networking, cloud computing 
            and web development. While I'm interested in freelance projects, 
            I'm also open to other opportunities or inquiries. Feel free to reach 
            out to me for any requests or questions. Let's connect and explore potential collaborations!





            </p>
          </div>
          {/* right */}
          <div className=" mx-auto">
            {/* box */}
            <div className="border border-[#ABB2BF] p-4">
              <h2 className=" mb-4 text-white font-semibold">
                Message me here
              </h2>
              {/* get in touch */}
              <div className="">
                {/* links */}
                <div className="flex gap-1 items-center">
                  <img src={require("./imgs/Email.png")} alt="" />
                  <span className="text-[#ABB2BF]">sugandika.hashini@gmail.com</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
