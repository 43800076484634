import "./about.css";

const About = () => {
  return (
    <>
      <div className="px-5 max-w-[1560px] mx-auto min-h-screen pt-20 flex items-center justify-between flex-wrap ">
        <div data-aos="fade-right" className="w-full sm:w-1/2 lg:w-5/12">
          <h1 className="font-semibold text-[32px] text-white mb-3">
            I am <span className="text-[#40908D]">Network Enthusiast</span> and{" "}
            <span className="text-[#40908D]">Eager Learner</span>
          </h1>
          <p className="text-[#ABB2BF] my-6">
            She crafts responsive websites where technologies meet creativity
          </p>
          <a href="https://drive.google.com/file/d/1Dvp7G6EdYRR6SUkzqbMhyLNEwr9skj-q/view?usp=sharing" target="_blank" rel="noopener noreferrer">
            <button className="text-white font-medium py-2 px-4 border duration-200 border-[#40908D] hover:bg-[#4B8F90]">
              Download CV
            </button>
          </a>
        </div>
        <div data-aos="fade-left" data-aos-delay="400" className="w-full sm:w-1/2 lg:w-5/12 flex justify-center mt-8 sm:mt-0">
          <div className="mx-auto">
            <img src={require("./imgs/girl.png")} alt="" />
            <div className="border flex items-center gap-2 border-[#ABB2BF] p-2 text-[#ABB2BF] mt-4">
              <div className="w-4 h-4 bg-[#40908D]"></div>
              <div className="">
                Currently <span className="text-white">Undergraduate</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div data-aos="fade-up" className="px-5 py-10">
        <img className="mx-auto" src={require("./imgs/quote.png")} alt="" />
      </div>
    </>
  );
};

export default About;
