const AboutSec = () => {
  return (
    <>
      <div className="px-5 max-w-[1560px] mx-auto mt-20 py-10">
        {/* bottom */}
        <div className="flex items-center">
          {/* left */}
          <div data-aos="fade-right" className="md:w-[50%] w-full">
            {/* title */}
            <div className="mb-12">
              <div className="text-white w-2/3 font-medium text-[32px] flex items-center gap-2">
                <div className="">
                  <span className="text-[#40908D]">#</span>about-me
                </div>
                <div className="line w-1/3 h-px bg-[#40908D]"></div>
              </div>
            </div>
            {/* disc */}
            <p className="text-[#ABB2BF]">
              Hello, I’m Hashini!
              <br />
              <br />
              I'm a passionate networking student based in Sri Lanka,
              eager to delve into networking technologies and build innovative
              solutions. Transforming my knowledge into practical solutions 
              has been my driving force. I enjoy exploring network architecture and protocols.
              <br />
              <br />
              Furthermore, I'm fascinated by cloud computing and its potential to revolutionize 
              networking infrastructure deployment and management. I'm also interested in web development, 
              where I use my networking knowledge to create efficient and scalable solutions.
              <br/>
              <br />
              Let's connect and explore the endless possibilities of networking together!
            </p>
          </div>
          {/* right */}
          <div data-aos="fade-left" className="md:w-[50%] w-full flex justify-center">
            <img className="mx-auto" src={require("./imgs/man.png")} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSec;
