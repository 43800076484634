import React from 'react';
import "./style.css";

const Project_card = ({ img, langs, title, disc, githubLink }) => {
  const handleViewClick = () => {
    window.open(githubLink, '_blank'); 
  };

  return (
    <>
      <div data-aos="fade-up" data-aos-delay="300" className="border mx-auto w-[90%] md:w-[48%] lg:w-[30%] border-[#ABB2BF] p-0">
        {/* img */}
        <div className="">
          <img className="w-full" src={img} alt="" />
        </div>
        {/* skills */}
        <div className="flex gap-2 flex-wrap p-2 border-y border-[#ABB2BF]">
          {langs.map((lang, index) => (
            <span key={index} className="text-[#ABB2BF]">
              {lang}
            </span>
          ))}
        </div>
        {/* disc */}
        <div className="p-4">
          <h2 className="text-[#FFFFFF] text-2xl font-medium">{title}</h2>
          <p className="py-4 text-[#ABB2BF]">{disc}</p>
          {/* btns */}
            <div onClick={handleViewClick} className="py-2 px-4 text-white border border-[#40908D] hover:bg-[#4B8F90] duration-150 project-view-btn">
              View {"<"}~{">"}
            </div>
        </div>
      </div>
    </>
  );
};

export default Project_card;
