// Projects.jsx
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import ProjectCard from '../project_card/Project_card';

const Projects = ({ projects }) => {
  // getting the current path location
  const currentLocation = useLocation();
  const isInProjectsPage = currentLocation.pathname !== "/projects";


  return (
    <div className="px-5 max-w-[1560px] mx-auto py-10">
      <div className="flex justify-between items-center gap-5">
        <div data-aos="fade-down" className="text-white w-2/3 font-medium text-[32px] flex items-center gap-2">
          <div className="">
            <span className="text-[#40908D]">#</span>projects
          </div>
          <div className="line w-2/3 h-px bg-[#40908D]"></div>
        </div>
        {isInProjectsPage? <div className="text-white font-medium">
          <Link to="/projects">
            <span>View All ~~&gt; </span>
          </Link>
        </div> : null }
      </div>
      <div className="flex flex-wrap justify-between gap-4 my-12">
        {projects.map((project, index) => (
          <ProjectCard key={index} {...project} />
        ))}
      </div>
    </div>
  );
};

export default Projects;
