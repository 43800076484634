import React from 'react';
import Japura from "../education_page/imgs/SJP.png";
 import Esoft from "../education_page/imgs/download (1).jpg";
 import Rathnavali from "../education_page/imgs/R.png";
 import Gurukula from "../education_page/imgs/OIP (2).jpg";

const Education = () => {
    // this contains education details regarding skills, certifications, badges
    return (
        <div className="px-5 max-w-[1560px] mx-auto py-10 ">
            {/* top */}
            <div data-aos="fade-left" className="mb-10">
                <div className="text-white w-2/3 font-medium text-[32px] flex items-center gap-2">
                    <div>
                        <span className="text-[#40908D]">#</span>education
                    </div>
                    <div className="line w-1/3 h-px bg-[#40908D]"></div>
                </div>
            </div>
            {/* bottom */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10 text-white">
                {/* First set of boxes */}
                <div className="flex flex-col gap-4">
                    {/* First Education entry - University */}
                    <div data-aos="fade-left" className="flex items-center gap-4">
                        {/* University Logo */}
                        <div className="w-10 h-10">
                            <img src={Japura} alt="University Logo" className="w-full h-full rounded-full bg-gray-500" />
                        </div>
                        {/* Education details */}
                        <div className="w-full border border-[#40908D]">
                            <div className="p-2">
                                <h2 className="font-semibold text-lg">Bacholer's Degree</h2>
                            </div>
                            <div className="flex flex-col gap-2 border-t border-[#ABB2BF] p-2">
                                <span>University of Sri Jayewardenepura</span>
                                <span>Faculty of Technology</span>
                                <span>Bachelor of Information and Communication Technology</span>
                                <span>2021 - Present</span>
                            </div>
                        </div>
                    </div>
                    {/* Second Education entry - HND */}
                    <div data-aos="fade-left" className="flex items-center gap-4">
                        {/* University Logo */}
                        <div className="w-10 h-10">
                            <img src={Esoft} alt="University Logo" className="w-full h-full rounded-full bg-gray-500" />
                        </div>
                        {/* Education details */}
                        <div className="w-full border border-[#40908D]">
                            <div className="p-2">
                                <h2 className="font-semibold text-lg">Higher National Diploma</h2>
                            </div>
                            <div className="flex flex-col gap-2 border-t border-[#ABB2BF] p-2">
                                <span>Esoft Metro Campus</span>
                                <span>Pearson BTEC Level 5 HND</span>
                                <span>2019-2022</span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Second set of boxes */}
                <div className="flex flex-col gap-4">
                    {/* Third Education entry - A/L */}
                    <div data-aos="fade-left" className="flex items-center gap-4">
                        {/* University Logo */}
                        <div className="w-10 h-10">
                            <img src={Rathnavali} alt="University Logo" className="w-full h-full rounded-full bg-gray-500" />
                        </div>
                        {/* Education details */}
                        <div className="w-full border border-[#40908D]">
                            <div className="p-2">
                                <h2 className="font-semibold text-lg">G. C. E. Advanced Level</h2>
                            </div>
                            <div className="flex flex-col gap-2 border-t border-[#ABB2BF] p-2">
                                <span>Rathnavali Balika Vidyalaya, Gampaha</span>
                                <span>2017-2019</span><br/><br/>
                            </div>
                        </div>
                    </div>
                    {/* Fourth Education entry - O/L */}
                    <div data-aos="fade-left" className="flex items-center gap-4">
                        {/* University Logo */}
                        <div className="w-10 h-10">
                            <img src={Gurukula} alt="University Logo" className="w-full h-full rounded-full bg-gray-500" />
                        </div>
                        {/* Education details */}
                        <div className="w-full border border-[#40908D]">
                            <div className="p-2">
                                <h2 className="font-semibold text-lg">G. C. E. Ordinary Level</h2>
                            </div>
                            <div className="flex flex-col gap-2 border-t border-[#ABB2BF] p-2">
                                <span>Gurukula National College, Kelaniya</span>
                                
                                <span>2014-2016</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Education;
