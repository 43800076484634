import About from './first_section_about/About';
import Projects from './projects_section/Projects';
import Skills from './skills_section/Skills';
import Contact from './contact_section/Contact';
import Self from './about_section/About.jsx';
import Education from './education_page/Education.jsx';

const Home = ({projectsList}) => {
    return(
        <>
            <About/>
            <Self/>
            <Education/>
            <Skills/>
            <Projects projects={projectsList}/>
            
            
            
            <Contact/>
        </>
    
    );
};

export default Home;